import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import ProfitCardWithGraph from 'components/grid/ProfitCardWithGraph';

function formatTimeAgo(dateString) {
  const now = new Date();
  const date = new Date(dateString);
  const diff = Math.floor((now - date) / 1000); // 차이를 초 단위로 계산

  if (diff < 60) {
    return `${diff}초 전`;
  } else if (diff < 3600) {
    const minutes = Math.floor(diff / 60);
    return `${minutes}분 전`;
  } else if (diff < 86400) {
    const hours = Math.floor(diff / 3600);
    return `${hours}시간 전`;
  } else if (diff < 7 * 86400) {
    const days = Math.floor(diff / 86400);
    return `${days}일 전`;
  } else {
    return date.toISOString().split('T')[0]; // 날짜 형식 (YYYY-MM-DD)
  }
}

function calculateProfitRate(buyPrice, sellPrice) {
  if (buyPrice <= 0) {
    throw new Error('Buy price must be greater than zero.');
  }

  const profitRate = ((sellPrice - buyPrice) / buyPrice) * 100;
  return parseFloat(profitRate.toFixed(2));
}

export default function Positions(props) {
  const [feeds, setFeeds] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const ProfitCard = (amt, buyPrice, sellPrice) => {
    const profitRate = ((sellPrice - buyPrice) / buyPrice) * 100;
    const isProfit = profitRate >= 0;

    return (
      <div
        className={`flex items-center justify-between p-4 rounded-lg shadow-md ${
          isProfit
            ? 'bg-green-800 bg-opacity-80 text-green-200'
            : 'bg-red-800 bg-opacity-80 text-red-200'
        }`}
      >
        {/* Symbol Section */}
        <div className="flex items-center space-x-3">
          <div className="flex items-center justify-center w-12 h-12 bg-gray-900 rounded-full text-lg font-bold text-white">
            {amt}
          </div>
          <div className="flex flex-col">
            <span className="text-sm font-semibold">Bought @ ${buyPrice}</span>
            <span className="text-sm">Sold @ ${sellPrice}</span>
          </div>
        </div>

        {/* Profit Rate Section */}
        <div className={`text-2xl font-bold ${isProfit ? 'text-green-400' : 'text-red-400'}`}>
          {isProfit ? '+' : ''}
          {profitRate}%
        </div>
      </div>
    );
  };

  const feedsView = feeds.map((item) => (
    <div key={item.id} className="bg-gray-950 shadow-lg rounded-lg p-4 border border-gray-700">
      <div className="flex justify-between items-center">
        <p>
          <span className="font-semibold"></span> {item.user_id}
          <span className="text-sm"> · {formatTimeAgo(item.sell_date)}</span>
        </p>
        <span
          className={`px-2 py-1 text-xs rounded-full ${
            ['BUY', 'LONG'].includes(item.side?.toUpperCase())
              ? 'bg-green-500 bg-opacity-20 text-green-400'
              : 'bg-red-500 bg-opacity-20 text-red-400'
          }`}
        >
          {item.side.toUpperCase() === 'BUY'
            ? 'LONG'
            : item.side.toUpperCase() === 'SELL'
            ? 'SHORT'
            : item.side}
        </span>
      </div>

      <div className="my-1">
        <span className="font-bold text-blue-400">{item.symbol}</span>
        <span className="">
          <span className="ml-3 font-semibold">$</span>
          <span className="font-semibold text-gray-100">
            {((item.sell_price - item.buy_price) * item.amt).toFixed(3)}
          </span>
          {/* <span className="text-xs ml-1">
            ({calculateProfitRate(item.buy_price, item.sell_price)}%)
          </span> */}
        </span>
      </div>
      <p className="text-sm">
        <span className="font-semibold"></span> {item.exchange}
      </p>
      {/* <p className="text-sm">
        <span className="font-semibold">Amount:</span> {item.amt}
      </p> */}
      {/* <p className="text-sm">
        <span className="font-semibold">Buy Price:</span> {item.buy_price}
      </p>
      <p className="text-sm">
        <span className="font-semibold">Sell Price:</span> {item.sell_price}
      </p> */}

      {/* <p className="text-sm">
        <span className="font-semibold">Buy Date:</span> {formatTimeAgo(item.buy_date)}
      </p> */}

      <ProfitCardWithGraph
        key={item.id}
        amt={item.amt}
        buyPrice={item.buy_price}
        sellPrice={item.sell_price}
        buyDate={item.buy_date}
        sellDate={item.sell_date}
      />
    </div>
  ));

  const getData = async () => {
    const url = 'https://gridapi.suseona.com/histories';
    try {
      const response = await fetch(url);
      const result = await response.json();
      console.log(result);
      setFeeds(result);
    } catch (error) {
      console.error('Failed to post data:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-950 p-4 text-gray-200 ">
      <div className="container mx-auto w-12/12 sm:w-120">
        <h1 className="text-3xl font-bold text-white mb-6">Trading Feeds</h1>
        <div className="grid gap-4">{feedsView}</div>
      </div>
    </div>
  );
}
