import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import dayjs from 'dayjs';

// Register necessary components for line chart
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const generateRandomPriceCurve = (buy_price, sell_price, buy_date, sell_date) => {
  // 날짜를 밀리초로 변환
  const buyDate = new Date(buy_date).getTime();
  const sellDate = new Date(sell_date).getTime();

  // 기간 (밀리초)
  const duration = sellDate - buyDate;

  // 생성할 랜덤 점의 수 (이 값을 변경하여 곡선의 세밀도를 조정)
  const numPoints = 5;

  // 랜덤 데이터 배열 생성
  const randomPoints = [];

  let formattedBuyDate = dayjs(buyDate);
  let buyLabel = formattedBuyDate.format('MM-DD HH:mm');

  randomPoints.push({ x: buyLabel, y: buy_price });

  for (let i = 1; i < numPoints; i++) {
    // 각 점의 시간 비율 (0부터 1까지)
    const ratio = i / numPoints;

    // 시간 비율에 맞는 x 값 (시간)
    const currentTime = buyDate + ratio * duration;
    // const caltime = sell_date - buy_date + buy_date;
    // const currentTime = new Date(caltime).getTime();

    // buy_price와 sell_price 사이의 랜덤한 y 값
    const randomPrice = buy_price + (sell_price - buy_price) * (Math.random() * 0.3 + 0.5); // 80%에서 100% 사이의 값 생성

    formattedBuyDate = dayjs(currentTime);

    buyLabel = formattedBuyDate.format('MM-DD HH:mm');

    // x, y 값으로 포인트 생성
    randomPoints.push({ x: buyLabel, y: randomPrice });
  }

  formattedBuyDate = dayjs(sellDate);
  buyLabel = formattedBuyDate.format('MM-DD HH:mm');

  randomPoints.push({ x: buyLabel, y: sell_price });

  return randomPoints;
};

// 예시로 사용하기

const ProfitCardWithLineGraph = ({ amt, buyPrice, sellPrice, buyDate, sellDate }) => {
  const profitRate = ((sellPrice - buyPrice) / buyPrice) * 100;
  const isProfit = profitRate >= 0;

  // Format dates for chart labels
  const formattedBuyDate = dayjs(buyDate);
  const formattedSellDate = dayjs(sellDate);

  const buyLabel = formattedBuyDate.format('MM-DD HH:mm');
  const sellLabel = formattedSellDate.format('MM-DD HH:mm');

  const randomCurve = generateRandomPriceCurve(buyPrice, sellPrice, buyDate, sellDate);

  console.log(randomCurve);

  // Data for Line Chart
  const chartData = {
    labels: [buyLabel], // Time labels (with date if different)
    datasets: [
      {
        label: 'Price', // Data label
        data: randomCurve,
        fill: false, // No fill between lines (for a clean line chart)
        borderColor: isProfit ? 'rgba(34, 197, 94, 1)' : 'rgba(239, 68, 68, 1)', // Green for profit, red for loss
        tension: 0.2, // Smooth curve
        // borderWidth: 2, // Line thickness
        // pointBackgroundColor: isProfit ? 'rgba(34, 197, 94, 1)' : 'rgba(239, 68, 68, 1)', // Point color matches the line
        pointRadius: 3, // Radius of the point markers
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: { display: false }, // Hide legend for a cleaner view
    },
    scales: {
      y: {
        beginAtZero: false, // Start from 0 for price values
        title: {
          display: true,
          text: 'Price (USD)',
        },
        ticks: {
          autoSkip: true,
          color: 'rgba(209, 213, 219, 1)', // Set Y-axis ticks color
        },
      },
      x: {
        title: {
          display: true,
          text: 'Time',
        },
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          color: 'rgba(209, 213, 219, 1)', // Set Y-axis ticks color
        },
      },
    },
  };

  //   <div
  //   className={`p-6 rounded-lg shadow-md space-y-4 ${
  //     isProfit
  //       ? 'bg-green-800 bg-opacity-80 text-green-200'
  //       : 'bg-red-800 bg-opacity-80 text-red-200'
  //   }`}
  // >

  return (
    <div className={`p-6 rounded-lg shadow-md space-y-4`}>
      {/* Header */}
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-lg font-bold">Quantity: {amt}</h2>
          {/* <p className="text-sm">
            Profit Rate: {isProfit ? '+' : ''}
            {profitRate.toFixed(2)}%
          </p> */}
        </div>
        <div className="text-xl font-bold">{profitRate.toFixed(3)}%</div>
      </div>

      {/* Line Chart */}
      <div className="w-full">
        <Line data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};

export default ProfitCardWithLineGraph;
