import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';

import UpbitClient from 'core/tribot/upbit/client';
import Feed from 'components/grid/Feed';

const Home = () => {
  const [ticker, setTicker] = useState({ symbol: '', markPrice: '' });
  const [amt, setAmt] = useState(0);

  useEffect(() => {
    asyncFetch();
  }, []);

  async function asyncFetch() {
    const tickers = await UpbitClient.getTickers();
    const filteredList = tickers.filter((item) => item.symbol === 'KRW-BTC');
    setTicker(filteredList[0]);
  }

  const onChangeAmt = (e) => {
    setAmt(e.target.value);
  };

  const clickBuy = () => {
    console.log('amt :' + amt);
    console.log('symbol :' + ticker.symbol);
  };

  return (
    <div className="text-white">
      <div>
        <Feed ticker={ticker} />
      </div>
    </div>
  );
};

export default Home;
